import { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import {
  remove,
  getDatabase,
  ref,
  update,
  push,
  child,
  get,
} from "firebase/database";
import Form from "react-bootstrap/Form";
import uploadSampleHL from "../uploadSampleHL.png";

export default function AddMedia() {
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [addingVideo, setAddingVideo] = useState(false);
  const [editingContent, setEditingContent] = useState(false);
  const [loadedValue, setLoadedValue] = useState(null);

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [listPosition, setListPosition] = useState(1);

  useEffect(() => {
    if (loading === true && editing === false) {
      const dbRef = ref(getDatabase());
      get(child(dbRef, `/videos/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setLoadedData(Object.entries(snapshot.val()));
            setLoading(false);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });

  function addVideo() {
    if (url.includes("http") || url.includes(".com")) {
      return alert("Please use only the video ID#, not the entire URL");
    }
    if (title !== "" && url !== "") {
      setEditing(false);

      const db = ref(getDatabase());
      push(child(db, "/videos/"), {
        title: title,
        url: url,
        listPosition: listPosition,
      });
      loadedData
        .filter((item) => item[1].listPosition >= 1)
        .forEach((item) =>
          update(child(db, `/videos/${item[0]}`), {
            listPosition: item[1].listPosition + 1,
          })
        );
      setLoading(true);
      setTitle("");
      setUrl("");
      setListPosition(1);
    }
  }

  function deleteVideo(entry) {
    const db = ref(getDatabase());
    remove(child(db, `/videos/${entry[0]}`));
    setLoading(true);
    setEditingContent(false);
  }

  function updateData(value) {
    const db = ref(getDatabase());
    if (!listPosition) {
      alert("Please enter list position");
    } else {
      update(child(db, `/videos/${value[0]}`), {
        title: title,
        url: url,
        listPosition: listPosition,
      });

      setLoading(true);
      setEditingContent(false);
    }
  }

  function moveVideoUp(value) {
    const db = ref(getDatabase());
    update(child(db, `/videos/${value[0]}`), {
      listPosition: value[1].listPosition - 1,
    });
    loadedData
      .filter((item) => item[1].listPosition === value[1].listPosition - 1)
      .forEach((item) =>
        update(child(db, `/videos/${item[0]}`), {
          listPosition: item[1].listPosition + 1,
        })
      );
    setLoading(true);
    setEditingContent(false);
  }

  function moveVideoDown(value) {
    const db = ref(getDatabase());
    update(child(db, `/videos/${value[0]}`), {
      listPosition: value[1].listPosition + 1,
    });
    loadedData
      .filter((item) => item[1].listPosition === value[1].listPosition + 1)
      .forEach((item) =>
        update(child(db, `/videos/${item[0]}`), {
          listPosition: item[1].listPosition - 1,
        })
      );
    setLoading(true);
    setEditingContent(false);
  }

  const displayData = loadedData
    .sort((a, b) => a[1].listPosition - b[1].listPosition)
    .map((entry, index) => (
      <div key={index} style={{ textAlign: "center" }}>
        <iframe
          key={index}
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${entry[1].url}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <p style={{ fontWeight: "bold" }}>
          {entry[1].title}
          {"\n\n"}
          {editingContent === true && loadedValue === entry[0] ? (
            <p>
              <button
                className="btn btn-secondary"
                onClick={() => moveVideoUp(entry)}
              >
                Move Up
              </button>
              {"\n"}
              <button
                className="btn btn-secondary"
                onClick={() => moveVideoDown(entry)}
              >
                Move Down
              </button>
              <br />
              <br />
              Title:{" "}
              <input
                className="editInput"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></input>
              <br />
              URL:{" "}
              <input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              ></input>
              <br />
              <button
                className="btn btn-success"
                onClick={() => updateData(entry)}
              >
                Publish Edits
              </button>
              {"\n"}
              <button
                className="btn btn-warning"
                onClick={() => setEditingContent(false)}
              >
                Stop Editing
              </button>
              {"\n"}
              <button
                className="btn btn-danger"
                onClick={() =>
                  window.confirm("Are you sure?")
                    ? deleteVideo(entry)
                    : alert("Delete cancelled")
                }
              >
                Delete
              </button>
            </p>
          ) : (
            <button
              className="btn btn-warning"
              onClick={() => {
                setEditingContent(true);
                setLoadedValue(entry[0]);
                setListPosition(entry[1].listPosition);
                setTitle(entry[1].title);
                setUrl(entry[1].url);
              }}
            >
              Edit
            </button>
          )}
        </p>
      </div>
    ));
  // const urltest = urls.map((entry, index) => console.log(Object.values(entry)))

  const addWindow = (
    <Form style={{ marginTop: 50 }}>
      <h1 style={{ textAlign: "center" }}>Add Video</h1>
      <Form.Group className="mb-3" controlId="formTitle">
        <Form.Control
          required
          type="text"
          placeholder="Enter title"
          onChange={(e) => {
            setTitle(e.target.value);
            setEditing(true);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formURL">
        <Form.Control
          required
          type="text"
          placeholder="Enter video ID# from URL"
          onChange={(e) => {
            setUrl(e.target.value);
            setEditing(true);
          }}
        />
      </Form.Group>
      <p style={{ textAlign: "left" }}>
        The ID# is in the URL and will look something like this:{" "}
        <Image src={uploadSampleHL} />
      </p>
      <br />
      <button
        style={{ width: 200 }}
        className="btn btn-success"
        onClick={() => addVideo()}
      >
        Submit
      </button>
      {"\n"}
      <button
        style={{ width: 200 }}
        className="btn btn-warning"
        onClick={() => setAddingVideo(false)}
      >
        Cancel
      </button>
    </Form>
  );

  return (
    <div className="row justify-content-center">
      <div className="col">
        {addingVideo === true ? (
          <p style={{ textAlign: "center" }}>{addWindow}</p>
        ) : (
          <p style={{ textAlign: "center" }}>
            {" "}
            <button
              style={{ marginTop: 50 }}
              className="btn btn-secondary"
              onClick={() => setAddingVideo(true)}
            >
              Add Video
            </button>
          </p>
        )}
        <h1 style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}>
          Currently Published Videos
        </h1>
        {displayData}
      </div>
    </div>
  );
}
