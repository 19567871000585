import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { Route, Routes } from "react-router-dom";
import { getDatabase, ref, child, get } from "firebase/database";
import './App.css';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useState, useEffect } from 'react';
import { Container, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import scscbannercentered from "../src/scscBannerCentered.png"
import './Gotham-Medium.otf';
import Nav from './Components/Nav';
import Account from './Components/Account';
import CurrentContent from "./Components/CurrentContent";
import SubmitContent from "./Components/SubmitContent";
import AddMedia from "./Components/AddMedia";
import React from 'react';


const firebaseConfig = {

  apiKey: process.env.REACT_APP_API_KEY,
         authDomain: process.env.REACT_APP_AUTH_DOMAIN,
         databaseURL: process.env.REACT_APP_DATABASE_URL,
         projectId: process.env.REACT_APP_PROJECT_ID,
         storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
         messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
         appId: process.env.REACT_APP_APP_ID,
         measurementId: process.env.REACT_APP_MEASUREMENT_ID
       };

      

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const database = getDatabase(app);


function App() {

  const user = getAuth().currentUser;

  const auth = getAuth();

  const [loadedData, setLoadedData] = useState(Object.entries({ huh: { firstName: "default", body: "default" } }));
  const [loading, setLoading] = useState(true);


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [editing, setEditing] = useState(false);



  useEffect(() => {
    if (loading === true && editing === false) {
      const dbRef = ref(getDatabase());
      get(child(dbRef, `/`)).then((snapshot) => {
        if (snapshot.exists()) {
          setLoadedData(Object.entries(snapshot.val()))
          setLoading(false);
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  });




  function signIn() {
    setEditing(false);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        // const user = userCredential.user;
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.code);
        const errorCode = error.code;
        if (errorCode === 'auth/user-not-found') {
          alert("No user by that name")
        };
        if (errorCode === 'auth/invalid-email') {
          alert("Please enter a valid email address")
        };
        if (errorCode === 'auth/wrong-password') {
          alert("That is not the password for this account.")
        };

      });
  }

  function triggerEmailReset(){
    sendPasswordResetEmail(auth, email)
    .then(() => {
      alert('Password email sent.')
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      alert(errorMessage)
    });
    }

  const resetPasswordModule = 
  <div>
    <br/>
    <h4>Forget your password?</h4>
  <input type="email" placeholder="email" value={email} onChange={e => { setEmail(e.target.value); setEditing(true) }}></input>
  <br/>
  <button className="btn btn-success" onClick={() => triggerEmailReset()}>Reset password</button>

  </div>


  if (!user) {
    return (
      <Container>
        <div className="row justify-content-center">
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <br />
            <h2>Please sign in.</h2>
            <br />
            <input type="email" placeholder="Email" value={email} onChange={e => { setEmail(e.target.value); setEditing(true) }}></input>
            <input type="password" placeholder="Password" value={password} onChange={e => { setPassword(e.target.value); setEditing(true) }}></input>
            <br />
            <button className="btn btn-success" onClick={() => signIn()}>Sign In</button>
      <br/>
      <br/>
      {resetPasswordModule}
          </div>
        </div>
      </Container>
    )
  }
  else {
    return (
      <div className="container">
        <div className="row">
          
        <Image src={scscbannercentered} />
        
        </div>
       
        <Nav />
        <div className="row">
        <div style={{textAlign:"center"}} className="loggedInGreeting">Hello, {user.displayName || user.email}</div>
        </div>
        <React.Fragment>
          <Routes>
            <Route path="/account/" element={<Account />} />
            <Route path="/current/" element={<CurrentContent />} />
            <Route path="/addmedia/" element={<AddMedia />} />
            <Route path="/" element={<SubmitContent />} />
          </Routes>
        </React.Fragment>
     </div>

    )
  }
}

export default App;
