import Dropdown from "react-bootstrap/Dropdown";
import { DropdownButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { getDatabase, ref, update, push, child, get } from "firebase/database";

export default function SubmitContent() {
  useEffect(() => {
    if (loading === true && editing === false) {
      const dbRef = ref(getDatabase());
      get(child(dbRef, `/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setLoadedData(Object.entries(snapshot.val()));
            setLoading(false);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });

  const [loadedData, setLoadedData] = useState(
    Object.entries({ huh: { firstName: "default", body: "default" } })
  );

  const [topic, setTopic] = useState("Choose Topic");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [source, setSource] = useState("");
  const [intro, setIntro] = useState("");
  const [listPosition, setlistPosition] = useState(1);
  const [subCategory, setSubCategory] = useState("Choose Category");

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);

  function writeData(event) {
    if (subCategory === "Choose Category") {
      alert("Please choose a topic");
      // setTitle("")
      // setBody("")
      // setSource("")
      // setTopic("Choose Topic")
      // setIntro("")
      // setlistPosition(1)
      // setSubCategory("Choose Subcategory")
      return;
    } else {
    }
    if (intro !== "" && title !== "" && body !== "") {
      setEditing(false);

      const db = ref(getDatabase());
      push(child(db, "/"), {
        intro: intro,
        topic: topic,
        title: title,
        body: body,
        source: source,
        listPosition: listPosition,
        subCategory: subCategory,
      });
      loadedData
        .filter((item) => item[1].topic === topic && item[1].listPosition >= 1)
        .forEach((item) =>
          update(child(db, `/${item[0]}`), {
            listPosition: item[1].listPosition + 1,
          })
        );
      setLoading(true);
      setTitle("");
      setBody("");
      setSource("");
      setTopic("Choose Topic");
      setIntro("");
      setlistPosition(1);
      setSubCategory("Choose Category");
    }
  }

  // const subCatDropdownTech = <Dropdown onSelect={(eventKey) => setSubCategory(eventKey)}>
  //   <DropdownButton title={subCategory}>

  //     <Dropdown.Item eventKey="Point of Sale & Barcode">Point of Sale & Barcode</Dropdown.Item>
  //     <Dropdown.Item eventKey="Modern Communications">Modern Communications</Dropdown.Item>
  //     <Dropdown.Item eventKey="Network & Physical Security">Network & Physical Security</Dropdown.Item>
  //   </DropdownButton>
  // </Dropdown>

  // const subCatDropdownMarket = <Dropdown onSelect={(eventKey) => setSubCategory(eventKey)}>
  //   <DropdownButton title={subCategory}>
  //     <Dropdown.Item eventKey="Public Sector">Public Sector</Dropdown.Item>
  //     <Dropdown.Item eventKey="Healthcare">Healthcare</Dropdown.Item>
  //     <Dropdown.Item eventKey="Manufacturing & Warehousing">Manufacturing & Warehousing</Dropdown.Item>
  //     <Dropdown.Item eventKey="Retail">Retail</Dropdown.Item>
  //     <Dropdown.Item eventKey="Point of Sale & Barcode">Point of Sale & Barcode</Dropdown.Item>
  //     <Dropdown.Item eventKey="Modern Communications">Modern Communications</Dropdown.Item>
  //     <Dropdown.Item eventKey="Network & Physical Security">Network & Physical Security</Dropdown.Item>
  //   </DropdownButton>
  // </Dropdown>

  const subCatDropdown = (
    <Dropdown onSelect={(eventKey) => setSubCategory(eventKey)}>
      <DropdownButton title={subCategory}>
        <Dropdown.Item eventKey="Public Sector">Public Sector</Dropdown.Item>
        <Dropdown.Item eventKey="Healthcare">Healthcare</Dropdown.Item>
        <Dropdown.Item eventKey="Manufacturing & Warehousing">
          Manufacturing & Warehousing
        </Dropdown.Item>
        <Dropdown.Item eventKey="Retail">Retail</Dropdown.Item>
        <Dropdown.Item eventKey="Point of Sale & Barcode">
          Point of Sale & Barcode
        </Dropdown.Item>
        <Dropdown.Item eventKey="Modern Communications">
          Modern Communications
        </Dropdown.Item>
        <Dropdown.Item eventKey="Network & Physical Security">
          Network & Physical Security
        </Dropdown.Item>
        <Dropdown.Item eventKey="Marketing Featured">
          Marketing Featured
        </Dropdown.Item>
        <Dropdown.Item eventKey="Intelisys">Intelisys</Dropdown.Item>
        <Dropdown.Item eventKey="Marketing Featured">
          Marketing Featured
        </Dropdown.Item>
        <Dropdown.Item eventKey="Tech Featured">
          Tech Topics Featured
        </Dropdown.Item>
        <Dropdown.Item eventKey="Education">Education</Dropdown.Item>
        <Dropdown.Item eventKey="Mobility">Mobility</Dropdown.Item>
        <Dropdown.Item eventKey="Entertainment">
          Arena and Entertainment
        </Dropdown.Item>
      </DropdownButton>
    </Dropdown>
  );

  return (
    <div className="row justify-content-center">
      <div className="col-12" style={{ textAlign: "center" }}>
        <h1 style={{ marginTop: 50 }}>Submit Content</h1>
        <br />
        {subCatDropdown}
        <br />
        <Form>
          <Form.Group className="mb-3" controlId="formTitle">
            <Form.Control
              required
              type="text"
              placeholder="Enter title"
              onChange={(e) => {
                setTitle(e.target.value);
                setEditing(true);
              }}
            />
          </Form.Group>
          <br />

          <Form.Group className="mb-3" controlId="formIntro">
            <Form.Control
              maxLength="200"
              as="textarea"
              rows={3}
              required
              placeholder="Listing intro"
              onChange={(e) => {
                setIntro(e.target.value);
                setEditing(true);
              }}
            />
            <Form.Text className="text-muted">
              <p>A short intro to the content. (200 character max)</p>
            </Form.Text>
          </Form.Group>
          <br />
          <Form.Group className="mb-3" controlId="formBody">
            <Form.Control
              as="textarea"
              rows={10}
              required
              placeholder="Enter article body"
              onChange={(e) => {
                setBody(e.target.value);
                setEditing(true);
              }}
            />
            <Form.Text className="text-muted">
              <p>This is the main body of the article</p>
            </Form.Text>
          </Form.Group>
          <br />
          <Form.Group className="mb-3" controlId="formSource">
            <Form.Control
              type="url"
              placeholder="Enter article source"
              onChange={(e) => {
                setSource(e.target.value);
                setEditing(true);
              }}
            />
            <Form.Text className="text-muted">
              <p>This is the URL linking to the source material</p>
            </Form.Text>
          </Form.Group>
          <br />
          {subCategory === "Choose Category" ? (
            <p style={{ width: 200 }} className="btn btn-secondary">
              Please choose category
            </p>
          ) : (
            <button
              style={{ width: 200 }}
              className="btn btn-success"
              onClick={() => writeData()}
            >
              Submit
            </button>
          )}
        </Form>
        <br />
        <br />
      </div>
    </div>
  );
}
