import React from 'react';
import {NavLink} from 'react-router-dom';
import '../App.css';

export default function Nav() {
  

  return(
    <div style={{paddingLeft:10,paddingRight:10}}className="navbar navRow">
    <NavLink to='/'>Add Article</NavLink>
    <NavLink to='/current/'>Edit Current Content</NavLink>
    <NavLink to="/addmedia/">Manage Videos</NavLink>
    <NavLink to="/account/">Account</NavLink>
    
    </div>
  );
}




