import {
  remove,
  getDatabase,
  ref,
  update,
  child,
  get,
} from "firebase/database";
import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownButton } from "react-bootstrap";

export default function CurrentContent() {
  const [loadedData, setLoadedData] = useState(
    Object.entries({ huh: { firstName: "default", body: "default" } })
  );
  const [loading, setLoading] = useState(true);
  // const [editing, setEditing] = useState(false);
  const [editingContent, setEditingContent] = useState(false);
  const [loadedValue, setLoadedValue] = useState(null);

  const [topic, setTopic] = useState("Choose Topic");
  const [subCategory, setSubCategory] = useState("");
  const [displayCategory, setDisplayCategory] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [source, setSource] = useState("");
  const [intro, setIntro] = useState("");
  const [listPosition, setListPosition] = useState(1);

  useEffect(() => {
    if (loading === true && editingContent === false) {
      const dbRef = ref(getDatabase());
      get(child(dbRef, `/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setLoadedData(Object.entries(snapshot.val()));
            setLoading(false);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });

  function raisePosition(value) {
    const db = ref(getDatabase());
    update(child(db, `/${value[0]}`), {
      listPosition: value[1].listPosition - 1,
    });
    loadedData
      .filter(
        (item) =>
          item[1].subCategory === subCategory &&
          item[1].listPosition === value[1].listPosition - 1
      )
      .forEach((item) =>
        update(child(db, `/${item[0]}`), {
          listPosition: item[1].listPosition + 1,
        })
      );
    setLoading(true);
    setEditingContent(false);
  }

  function lowerPosition(value) {
    const db = ref(getDatabase());
    update(child(db, `/${value[0]}`), {
      listPosition: value[1].listPosition + 1,
    });
    loadedData
      .filter(
        (item) =>
          item[1].subCategory === subCategory &&
          item[1].listPosition === value[1].listPosition + 1
      )
      .forEach((item) =>
        update(child(db, `/${item[0]}`), {
          listPosition: item[1].listPosition - 1,
        })
      );
    setLoading(true);
    setEditingContent(false);
  }

  function deleteContent(value) {
    const db = ref(getDatabase());
    loadedData
      .filter(
        (item) =>
          item[1].subCategory === subCategory &&
          item[1].listPosition >= value[1].listPosition
      )
      .forEach((item) =>
        update(child(db, `/${item[0]}`), {
          listPosition: item[1].listPosition - 1,
        })
      );
    remove(child(db, `/${value[0]}`));
    setLoading(true);
    setEditingContent(false);
  }

  function updateData(value) {
    const db = ref(getDatabase());
    // if(!listPosition){
    //   alert("Please enter list position")
    // }
    // else{
    update(child(db, `/${value[0]}`), {
      topic: topic,
      intro: intro,
      body: body,
      title: title,
      source: source,
      listPosition: listPosition,
      subCategory: subCategory,
    });

    setLoading(true);
    setEditingContent(false);
  }

  //       const topicDropdown = <Dropdown onSelect={(eventKey) => setTopic(eventKey)}>
  //   <DropdownButton title={topic}>
  //     <Dropdown.Item eventKey="Market Trends">Market Trends</Dropdown.Item>
  //     <Dropdown.Item eventKey="Tech Topics">Tech Topics</Dropdown.Item>
  //   </DropdownButton>
  // </Dropdown>

  const allDropdown = (
    <Dropdown onSelect={(eventKey) => setDisplayCategory(eventKey)}>
      <DropdownButton
        title={displayCategory !== "" ? displayCategory : "Choose Category"}
      >
        <Dropdown.Item eventKey="All">All Categories</Dropdown.Item>
        <Dropdown.Item eventKey="Point of Sale & Barcode">
          Point of Sale & Barcode
        </Dropdown.Item>
        <Dropdown.Item eventKey="Modern Communications">
          Modern Communications
        </Dropdown.Item>
        <Dropdown.Item eventKey="Network & Physical Security">
          Network & Physical Security
        </Dropdown.Item>
        <Dropdown.Item eventKey="Public Sector">Public Sector</Dropdown.Item>
        <Dropdown.Item eventKey="Healthcare">Healthcare</Dropdown.Item>
        <Dropdown.Item eventKey="Manufacturing & Warehousing">
          Manufacturing & Warehousing
        </Dropdown.Item>
        <Dropdown.Item eventKey="Retail">Retail</Dropdown.Item>
        <Dropdown.Item eventKey="Marketing Featured">
          Marketing Featured
        </Dropdown.Item>
        <Dropdown.Item eventKey="Tech Featured">
          Tech Topics Featured
        </Dropdown.Item>
        <Dropdown.Item eventKey="Intelisys">Intelisys</Dropdown.Item>
        <Dropdown.Item eventKey="Education">Education</Dropdown.Item>
        <Dropdown.Item eventKey="Mobility">Mobility</Dropdown.Item>
        <Dropdown.Item eventKey="Entertainment">
          Arena and Entertainment
        </Dropdown.Item>
      </DropdownButton>
    </Dropdown>
  );

  const subCategoryDropdown = (
    <Dropdown onSelect={(eventKey) => setSubCategory(eventKey)}>
      <DropdownButton
        title={subCategory !== "" ? subCategory : "Choose Category"}
      >
        <Dropdown.Item eventKey="Point of Sale & Barcode">
          Point of Sale & Barcode
        </Dropdown.Item>
        <Dropdown.Item eventKey="Modern Communications">
          Modern Communications
        </Dropdown.Item>
        <Dropdown.Item eventKey="Network & Physical Security">
          Network & Physical Security
        </Dropdown.Item>
        <Dropdown.Item eventKey="Public Sector">Public Sector</Dropdown.Item>
        <Dropdown.Item eventKey="Healthcare">Healthcare</Dropdown.Item>
        <Dropdown.Item eventKey="Manufacturing & Warehousing">
          Manufacturing & Warehousing
        </Dropdown.Item>
        <Dropdown.Item eventKey="Retail">Retail</Dropdown.Item>
        <Dropdown.Item eventKey="Marketing Featured">
          Marketing Featured
        </Dropdown.Item>
        <Dropdown.Item eventKey="Tech Featured">
          Tech Topics Featured
        </Dropdown.Item>
        <Dropdown.Item eventKey="Intelisys">Intelisys</Dropdown.Item>
        <Dropdown.Item eventKey="Education">Education</Dropdown.Item>
        <Dropdown.Item eventKey="Mobility">Mobility</Dropdown.Item>
        <Dropdown.Item eventKey="Entertainment">
          Arena and Entertainment
        </Dropdown.Item>
      </DropdownButton>
    </Dropdown>
  );

  const displayData =
    loadedData &&
    loadedData
      .filter(
        (value) =>
          displayCategory === "All" || value[1].subCategory === displayCategory
      )
      .sort((a, b) => a[1].listPosition - b[1].listPosition)
      .map((value, index) => (
        <div style={{ marginBottom: 75 }} key={index}>
          {/* {editingContent===true && loadedValue===value[0] ? topicDropdown : <p><span className="articleCategory">Topic:</span> {value[1].topic}</p>} */}
          {/* <br/> */}
          {editingContent === true && loadedValue === value[0] ? (
            subCategoryDropdown
          ) : (
            <p>
              <span className="articleCategory">Category:</span>{" "}
              {value[1].subCategory}
            </p>
          )}
          <br />
          {editingContent === true && loadedValue === value[0] ? (
            <p>
              <span className="articleCategory">Title:</span>{" "}
              <input
                className="editInput"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></input>
            </p>
          ) : (
            <p>
              <span className="articleCategory">Title:</span> {value[1].title}
            </p>
          )}
          {editingContent === true && loadedValue === value[0] ? (
            <p>
              <span className="articleCategory">
                Intro: (200 character max)
              </span>{" "}
              <textarea
                className="editInput"
                maxLength="200"
                rows={5}
                value={intro}
                onChange={(e) => setIntro(e.target.value)}
              ></textarea>
            </p>
          ) : (
            <p>
              <span className="articleCategory">Intro:</span> {value[1].intro}
            </p>
          )}
          {editingContent === true && loadedValue === value[0] ? (
            <p>
              <span className="articleCategory">Body:</span>{" "}
              <textarea
                className="editInput"
                rows={10}
                value={body}
                onChange={(e) => setBody(e.target.value)}
              ></textarea>
            </p>
          ) : (
            <p style={{ whiteSpace: "pre-line" }}>
              <span className="articleCategory">Body:</span> {value[1].body}
            </p>
          )}
          {editingContent === true && loadedValue === value[0] ? (
            <p>
              <span className="articleCategory">Source:</span>{" "}
              <input
                className="editInput"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              ></input>
            </p>
          ) : (
            <p>
              <span className="articleCategory">Source:</span> {value[1].source}
            </p>
          )}
          {/* {editingContent===true && loadedValue===value[0] ? <p><span className="articleCategory">List Position:</span> <input type="number" className="editInput" value={listPosition} onChange={e => setListPosition(parseInt(e.target.value))}></input></p> : <p><span className="articleCategory">List Position:</span> {value[1].listPosition}</p> } */}
          {/* {editingContent===true && loadedValue===value[0] ? <p><span className="articleCategory">List Position:</span> {value[1].listPosition}</p> : <p><span className="articleCategory">List Position:</span> {value[1].listPosition}</p> } */}

          {editingContent === false && (
            <button
              className="btn btn-warning"
              onClick={() => {
                setEditingContent(true);
                setLoadedValue(value[0]);
                setTitle(value[1].title);
                setTopic(value[1].topic);
                setIntro(value[1].intro);
                console.log(value[1].body);
                setBody(value[1].body);
                setSource(value[1].source);
                setListPosition(value[1].listPosition);
                setSubCategory(value[1].subCategory);
              }}
            >
              Edit
            </button>
          )}
          {editingContent === true && loadedValue === value[0] ? (
            <div style={{ textAlign: "center" }}>
              <p>
                <button
                  className="btn btn-secondary"
                  onClick={() => raisePosition(value)}
                >
                  Move Up
                </button>
                {"\n"}
                <button
                  className="btn btn-secondary"
                  onClick={() => lowerPosition(value)}
                >
                  Move Down
                </button>
                <br />
                <button
                  className="btn btn-success"
                  onClick={() => updateData(value)}
                >
                  Submit Edit
                </button>{" "}
                <button
                  className="btn btn-warning"
                  onClick={() => setEditingContent(false)}
                >
                  Stop Editing
                </button>{" "}
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    window.confirm("Are you sure?")
                      ? deleteContent(value)
                      : alert("Delete cancelled")
                  }
                >
                  Delete
                </button>
              </p>
              <br />
            </div>
          ) : null}
        </div>
      ));

  return (
    <div className="row justify-content-center">
      <div className="col">
        <h1 style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}>
          Currently Published Articles
        </h1>
        <div style={{ textAlign: "center" }}>{allDropdown}</div>
        {loading === false && displayData}
        <br />
        <br />
      </div>
    </div>
  );
}
