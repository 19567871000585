import {
  EmailAuthProvider,
  updateProfile,
  sendPasswordResetEmail,
  getAuth,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useState } from "react";

function Account() {
  const user = getAuth().currentUser;

  const auth = getAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [newPassword, setNewPassword] = useState("");
  // const [editing, setEditing] = useState(false);

  const [displayName, setDisplayName] = useState("");
  const [updateWindowVisible, setUpdateWindowVisible] = useState(false);

  // function signIn() {
  //   setEditing(false);

  //   signInWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       // Signed in
  //       const user = userCredential.user;
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       console.log(error.code);
  //       const errorCode = error.code;
  //       if (errorCode === 'auth/user-not-found') {
  //         alert("No user by that name")
  //       };
  //       if (errorCode === 'auth/invalid-email') {
  //         alert("Please enter a valid email address")
  //       };
  //       if (errorCode === 'auth/wrong-password') {
  //         alert("I'm sorry, that's not the password for this user's secret, illegal account.")
  //       };

  //     });
  // }
  function signOut() {
    auth.signOut();
    window.location.reload();
  }

  function changePassword() {
    if (password === "" || password2 === "") {
      return alert("Please enter and re-enter your password");
    }
    if (newPassword === "") {
      return alert("Please enter a new password");
    }

    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );
    password !== password2
      ? alert("The passwords don't match")
      : reauthenticateWithCredential(user, credential)
          .then(() => {
            updatePassword(user, newPassword)
              .then(() => {
                alert("Password updated!");
                setPassword("");
                setPassword2("");
                setNewPassword("");
              })
              .catch((error) => {
                const errorCode = error.code;
                console.log(errorCode);
              });
          })
          .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode);
            if (errorCode === "auth/wrong-password") {
              alert("Sorry, that's not the right password");
            }
          });
  }

  function changeDisplayName() {
    updateProfile(auth.currentUser, {
      displayName: displayName,
    })
      .then(() => {
        alert("Display name updated!");
        setDisplayName("");
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
      });
  }

  function triggerEmailReset() {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Password email sent.");
        setEmail("");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert(errorMessage);
      });
  }

  return (
    <div>
      <h1 style={{ marginTop: 50, marginBottom: 50, textAlign: "center" }}>
        User Account
      </h1>
      <div style={{ textAlign: "center" }}>
        <button className="btn btn-secondary" onClick={() => signOut()}>
          Sign out
        </button>
      </div>
      <p>Email: {user.email}</p>
      <p>Display name: {user.displayName || "No name supplied"} </p>
      {updateWindowVisible === true ? (
        <div>
          <input
            type="text"
            placeholder="Display Name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          ></input>
          <br />
          <button
            className="btn btn-primary"
            onClick={() => changeDisplayName()}
          >
            Update Display Name
          </button>
          <br />
          <br />
          <br />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <br />
          <input
            type="password"
            placeholder="Verify Password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          ></input>
          <br />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          ></input>
          <br />
          <button className="btn btn-primary" onClick={() => changePassword()}>
            Update Password
          </button>
          <br />
          <br />
          <p>
            Forgot your password? Please enter the email for this account and
            hit RESET PASSWORD
          </p>
          <p>If you don't see the email, check your SPAM folder.</p>
          <input
            type="email"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <br />

          <button
            className="btn btn-primary"
            onClick={() => triggerEmailReset()}
          >
            Reset Password
          </button>
          {/* <button onClick={()=>console.log(user)}>Log User</button> */}
          <br />
          <br />
          <button
            className="btn btn-secondary"
            onClick={() => setUpdateWindowVisible(false)}
          >
            Stop Editing Profile
          </button>
        </div>
      ) : (
        <button
          className="btn btn-secondary"
          onClick={() => setUpdateWindowVisible(true)}
        >
          Edit Profile
        </button>
      )}
    </div>
  );
}
export default Account;
